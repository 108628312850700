@import "../../bower_components/bootstrap/scss/functions";
@import "variables";
@import "../../bower_components/bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css?family=Oswald:200,300,400,500,600,700&subset=cyrillic');
@import "fa/fontawesome";
@import "fa/fa-brands";


#top-bar {
    &>* {
        padding: 10px 0;
    }
    #date-block {
        font-size: 16px;
        fint-style: italic;
    }

    #search_form {
        #search_query {
        }
        button {
            width: 40px;
        }
    }
}

a.logo {
    font-size: 60px;
    line-height: 60px;
    text-decoration: none;
    font-weight: 300;
    font-style: italic;
    color: #000;
    letter-spacing: -3.9px;
    margin-bottom: 20px;
    span {
        font-weight: 700;
        font-style: normal;
    }
    &:visited, &:active, &:hover {
    }
}

#navigation {
    margin-top: 10px;
    margin-bottom: 10px;
}

#image_intro {
    a {
        display: block;
        position: relative;
        color: black;
        height: 305px;
        overflow: hidden;

        img {
            width: 100%;
        }

        .category {
            background: yellow;
            position: absolute;
            top: 10px;
            left: 10px;
            padding: 5px;
            border-radius: 5px;
            border: 1px solid transparent;
            font-size: .7rem;
        }
        .title {
            background: transparent;
            position: absolute;
            bottom: 10px;
            margin: 25px;
            font-size: 1.3rem;
            color: white;
        }
    }
}

#introes {
    margin-top: 15px;
    margin-bottom: 15px;

    a.intro {
        display: block;
        position: relative;
        color: black;
        min-height: 120px;
        max-height: 305px;
        margin-bottom: 2rem;
        overflow: hidden;

        img {
            width: 100%;
        }
        .title {
            display: block;
            background: transparent;
            font-size: 1.3rem;
        }
        .intro {
            padding-top: 15px;
            font-size: 1rem;
        }
    }
}

#ya_news {
    border: 1px solid $orange;
    border-radius: 15px 0 15px 0;
    padding: 2rem 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

aside {
    .ads {
        margin-bottom: 25px;
    }

    #aside_navigation {
        margin-bottom: 25px;
        a {
            display: block;
            padding: 3px 10px;
            &:link, &:active, &:visited {
                border-bottom: 1px dotted #d2d1d1;
                color: black;
            }
        }
    }
}


#artcile {
    padding-bottom: 1rem;
    margin-bottom: 3rem;
    border-bottom: 1px $gray-600 solid; 
    #category {
        background: yellow;
        color: $black;
        margin: 10px;
        padding: 5px;
        border-radius: 15px 0 15px 0;
        border: 1px solid $black;
        text-transform: uppercase;

        a {
            &:link, &:active, &:visited {
                color: $black;
            }
            &:hover {
                text-decoration: none;
                color: $blue;
            }
        }
    }

    a.content_image {
        display: inline-block;
        float: left;
        margin: 0 1rem 1rem 0;
    }
}



footer#page_footer {
    margin-top: 3rem;
    margin-bottom: 3rem;
    border-top: 1px $black solid;

    .row {
        padding-top: 2rem;
        line-height: 80px;
    }
}